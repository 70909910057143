import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';

import Hub, { VARIANT as HUB_VARIAN } from '../../components/Hub';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';

const messages = defineMessages({
  title: {
    id: 'index.title.CSGO',
    defaultMessage:
      'Sell CS:GO Skins for Real Money & Withdraw to the e-wallet of your choice',
  },
  description: {
    id: 'index.description.CSGO',
    defaultMessage:
      'Sell your CSGO skins fast and cash out instantly! Log in with your Steam, evaluate your inventory, sell skins and pay out money in 5 minutes.',
  },
});

const HubPage = ({
  location: { pathname, hash },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const hubVariant = Object.values(HUB_VARIAN).find(
    (variant) => variant === hash.replace('#', ''),
  );

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        pathname={pathname}
        robots="noindex, follow"
      />
      <Layout
        originalPath={originalPath}
        shouldHideOnScroll={false}
        isFooterVisible={false}
      >
        <Hub variant={hubVariant} />
      </Layout>
    </>
  );
};

HubPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default HubPage;
